import { Injectable } from '@angular/core';

const themeA = require('app/admin/shared/styles/themes/theme-a.scss');
const admin = require('app/admin/shared/styles/theme.scss');
const web = require('app/webapp/shared/styles/theme.scss');

@Injectable()
export class ThemesService 
{
	// set webapp theme
	public setWebappTheme(name) 
	{
		switch (name)
		{
			case 'admin':
				this.injectStylesheet(admin);
				this.injectStylesheet(themeA);
				break;

			case 'web':
				this.injectStylesheet(web);
				break;
		}
	}

	// inject css
	private injectStylesheet(css: any)
	{
		const head = document.head || document.getElementsByTagName('head')[0];
		const styleTag = document.createElement('style');
		styleTag.type = 'text/css';
		styleTag.innerHTML = css;

		head.appendChild(styleTag);
	}
}
