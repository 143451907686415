import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InAppNotification, InAppNotificationsCount, NotificationMessageRequest, PushNotificationRecipient, PushSubscription } from './notifications.models';

@Injectable()
export class NotificationsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// subscribe
	public subscribe(subscription: PushSubscription)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/subscribe`, subscription);
	}

	// unsubscribe
	public unsubscribe(subscription?: PushSubscription)
	{
		if (subscription)
			return this.http.post(`${this.baseUrl}api/shared/notifications/unsubscribe`, subscription);
		else
			return this.http.post(`${this.baseUrl}api/shared/notifications/unsubscribe`, {});
	}

	// get subscription status
	public getSubscriptionStatus(subscription: PushSubscription)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/subscriptionStatus`, subscription);
	}

	// get push notifications recipients
	public getPushNotificationsRecipients(): Observable<PushNotificationRecipient[]>
	{
		return this.http.get<PushNotificationRecipient[]>(`${this.baseUrl}api/shared/notifications/pushNotificationsRecipients`);
	}

	// send notification
	public sendNotification(notificationMessage: NotificationMessageRequest)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/sendNotification`, notificationMessage);
	}

	// get user notifications
	public getUserNotifications(inbound: boolean, outbound: boolean): Observable<InAppNotification[]>
	{
		return this.http.get<InAppNotification[]>(`${this.baseUrl}api/shared/notifications/userNotifications?inbound=${inbound}&outbound=${outbound}`);
	}

	// get user notifications count
	public getUserNotificationsCount(): Observable<InAppNotificationsCount>
	{
		return this.http.get<InAppNotificationsCount>(`${this.baseUrl}api/shared/notifications/userNotificationsCount`);
	}

	// get notification
	public getNotification(id: string): Observable<InAppNotification>
	{
		return this.http.get<InAppNotification>(`${this.baseUrl}api/shared/notifications/notification/${id}`);
	}

	// set notification as read
	public setNotificationAsRead(id?: string)
	{
		return this.http.post(`${this.baseUrl}api/shared/notifications/setNotificationAsRead`, { id: id });
	}
}